import "./App.css";
import logo from "./NG final transparent.png";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CssBaseline from "@mui/material/CssBaseline";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitch,
  faYoutube,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const links = [
  {
    id: "twitch",
    display: "Twitch",
    link: "https://www.twitch.tv/nostalgiiagames",
    icon: faTwitch,
  },
  {
    id: "youtube",
    display: "YouTube",
    link: "https://www.youtube.com/channel/UCBL2GyGVtJKnKU3h84LNPLA",
    icon: faYoutube,
  },
  {
    id: "twitter",
    display: "Twitter",
    link: "https://twitter.com/NostalgiiaGames",
    icon: faTwitter,
  },
];

function App() {
  return (
    <Box
      component="main"
      sx={{
        bgcolor: "black.main",
        flexGrow: 1,
        height: "100%",
        overflow: "auto",
        justifyContent: "center",
      }}
    >
      <CssBaseline />

      <Container
        maxWidth={false}
        sx={{
          pt: 2,
          pb: 2,
          maxWidth: "640px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar
          alt="@nostalgiiagames logo"
          src={logo}
          sx={{
            width: 108,
            height: 108,
            borderWidth: "4px",
            borderStyle: "solid",
            borderColor: "colors.primary",
          }}
        />

        <Typography
          component="h1"
          variant="h4"
          color="colors.secondary"
          noWrap
          sx={{ mt: 2 }}
        >
          @NostalgiiaGames
        </Typography>
      </Container>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "640px",
          mr: "auto",
          ml: "auto",
          mt: 2,
        }}
      >
        <Box
          sx={{
            m: 0,
            maxWidth: "100%",
            display: "flex",
            width: "100%",
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} key={`2`}>
              <List dense={false} sx={{}}>
                {links.map((link) => (
                  <ListItem key={link.id}>
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<FontAwesomeIcon icon={link.icon} />}
                      sx={{
                        width: "100%",
                        height: "64px",
                        textTransform: "none",
                        borderWidth: "2px",
                        fontFamily: "RetroGaming"
                      }}
                      size="large"
                      href={link.link}
                      target="_blank"
                    >
                      {link.display}
                    </Button>
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default App;
